import React from "react";
import { useSelector } from "react-redux";
import EventOverview from "../../components/event-overview";
import FestivalOverview from "../../components/festival-overview";

export default function Landing(props) {
    const { auth, desc, img } = props;
    const festivals = useSelector((state) => state.guest.festivals);
    const fetchingEvents = useSelector((state) => state.guest.fetchingEvents);
    const events = useSelector((state) => state.guest.byDate);

    const Header = () => {
        return (
            <div className="container">
                <div className="row justify-content-center pt-120 pb-80">
                    <div className="col-md-6 col-11">
                        <h1 className="super">Viðburða&shy;skráning</h1>
                        <div className="description">
                            <div className="text" dangerouslySetInnerHTML={{ __html: desc }}></div>
                            <a href={`${auth === "True" ? "/home/events/new" : "/login"}`}>
                                <button className="primary mt-5">Skrá viðburð</button>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-11 header-image-container">
                        <img src={img} className="w-100" />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Header />
            {festivals.length > 0 && (
                <FestivalOverview {...props} festivals={festivals} title={"Hátíðir"} showDetails={true} />
            )}
            <EventOverview
                {...props}
                fetching={fetchingEvents}
                events={events}
                renderType={"date"}
                title={"Viðburðir"}
                fetchMoreButton={true}
            />
        </>
    );
}
