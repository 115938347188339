import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import moment from "moment"
import { useGetEventDetailQuery } from "../../api/events-list"
import { TIME_FORMAT } from "../../constants"
import EventMap from "../event-card/map"
import { BackArrowIcon, ClockIcon, FbIcon, LocationIcon, WebIcon, WheelchairIcon, PersonClockIcon } from "../svg"

export default function EventDetails() {
    const params = useParams()
    const navigate = useNavigate()
    const { data: event } = useGetEventDetailQuery({ id: params.id, anyEvent: true })
    const user = useSelector((state) => state.user.info)

    const doEditEvent = useCallback((ev) => {
        navigate(`/events/${ev.id}/edit`)
    }, [])

    // Dates are stored differently for Template and non template events
    function getDateTimes(ev) {
        let startDate = ""
        let endDate = ""
        let startTime = ""
        let endTime = ""
        if (ev.template) {
            if (!ev.dates.length) {
                return <p>Engar tímasetningar skráðar</p>
            }
            if (ev.occurrence == "single" || ev.occurrence == "range") {
                startDate = moment(ev.dates[0].start).format("D. MMMM YYYY")
                endDate = moment(ev.dates[0].end).format("D. MMMM YYYY")
                startTime = moment(event.dates[0].start).format(TIME_FORMAT)
                endTime = moment(event.dates[0].end).format(TIME_FORMAT)
            } else if (ev.occurrence == "multiple") {
                startDate = moment(ev.dates[0].start).format("D. MMMM YYYY")
                return (
                    <div className="">
                        <p>{startDate}</p>
                        <p>{event.dates.length} aðrar dagsetningar skráðar</p>
                    </div>
                ) // This should be a link
            }
        } else {
            startDate = moment(ev.start).format("D. MMMM YYYY")
            endDate = moment(ev.end).format("D. MMMM YYYY")
            startTime = moment(event.start).format(TIME_FORMAT)
            endTime = moment(event.end).format(TIME_FORMAT)
        }

        return (
            <>
                <p>Tímasetning</p>
                {startDate == endDate ? (
                    <p>{startDate}</p>
                ) : (
                    <p>
                        {startDate} - {endDate}
                    </p>
                )}
                <p>
                    {startTime} - {endTime}
                </p>
            </>
        )
    }

    return (
        <>
            <div className="container back-btn">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-11 d-flex align-items-center">
                        <BackArrowIcon />
                        <button
                            onClick={() => {
                                navigate(-1)
                            }}
                            className="ml-2"
                        >
                            Til baka
                        </button>
                    </div>
                </div>
            </div>
            {event && (
                <>
                    <div className="container event-view">
                        {event.template && (
                            <div className="row">
                                <div className="col-12 position-relative mt-50">
                                    {((user && user.permissions && user.permissions.superuser) ||
                                        event.owner === user.id) && (
                                        <button
                                            className="secondary thin position-absolute"
                                            style={{
                                                left: "50%",
                                                transform: "translateX(-50%",
                                                top: "-140px",
                                                height: "58px",
                                            }}
                                            onClick={() => doEditEvent(event)}
                                        >
                                            Breyta viðburði
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <h1>{event.title}</h1>
                            </div>
                        </div>
                        <div className="border-top border-bottom row time mb-5 gap-y-4">
                            <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center">
                                <ClockIcon />
                                <div>
                                  {getDateTimes(event)}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center">
                                <LocationIcon />
                                <div>
                                  <p>Staðsetning</p>
                                  <p>{event.place}</p>
                                  <p>{event.formatted_address}</p>
                                </div>
                            </div>
                            {event.birth_year_from && event.birth_year_to && (
                                <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center">
                                    <PersonClockIcon />
                                    <div>
                                      <p>
                                          Aldurshópur <br />
                                          {event.birth_year_from} - {event.birth_year_to}
                                      </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row links">
                            {event.media && (
                                <div className="col-12 p-0 d-flex">
                                    {event.media.tickets && (
                                        <a href={event.media.tickets} className="d-flex align-items-center mr-5">
                                            <WebIcon />
                                            <h5 className="ml-3">Miðasala</h5>
                                        </a>
                                    )}
                                    {event.media.website && (
                                        <a
                                            href={event.media.website}
                                            className="d-flex align-items-center mr-5"
                                            target="_blank"
                                        >
                                            <WebIcon />
                                            <h5 className="ml-3">Vefsíða</h5>
                                        </a>
                                    )}
                                    {event.media.facebook && (
                                        <a
                                            href={event.media.facebook}
                                            className="d-flex align-items-center mr-5"
                                            target="_blank"
                                        >
                                            <FbIcon />
                                            <h5 className="ml-3">Facebook</h5>
                                        </a>
                                    )}
                                    {event.wheelchair_access && (
                                        <div className="d-flex align-items-center mr-5">
                                            <WheelchairIcon />
                                            <h5 className="ml-3">Þessi staður er með hjólastólaaðgengi</h5>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12 p-0">
                                {event.event_image && (
                                    <img src={event.event_image} className="event-img modal-img w-100 mb-5" />
                                )}
                                <div>
                                    <div className="text">{event.description}</div>
                                    <br />
                                    <div className="text">{event.detailed_description}</div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    {event.latitude && event.longitude && (
                        <div className="map-container">
                            <EventMap
                                event={event}
                                location={[event.latitude, event.longitude]}
                                google={window.google}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    )
}
