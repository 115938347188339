import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getCookie } from "../utils";

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers) => {
            const csrftoken = getCookie("csrftoken");
            if (csrftoken) {
                headers.set("X-CSRFToken", csrftoken);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
    reducerPath: "api",
});

export const anonApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: (headers) => {
            const csrftoken = getCookie("csrftoken");
            if (csrftoken) {
                headers.set("X-CSRFToken", csrftoken);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
    reducerPath: "anonApi",
});
