import { anonApi, api } from "../index";

export const eventsApi = api
    .enhanceEndpoints({
        addTagTypes: ["Event"],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getEvents: build.query({
                query: ({ group, category, date, postal, type, owner, search, page = 1, pageSize = 30 }) => ({
                    url: `events/`,
                    params: {
                        group,
                        category,
                        date,
                        postal,
                        type,
                        owner,
                        q: search,
                        limit: pageSize,
                        offset: (page - 1) * pageSize,
                    },
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.results.map(({ id }) => ({
                                  type: "Event",
                                  id,
                              })),
                              { type: "Event", id: "LIST" },
                          ]
                        : [{ type: "Event", id: "LIST" }],
            }),

            getEventDetail: build.query({
                query: ({ id, anyEvent = false }) => ({
                    url: `events/${id}/`,
                    params: {
                        any_event: anyEvent,
                    },
                }),
                providesTags: (result, error, { id }) => (result ? [{ type: "Event", id }] : []),
            }),

            copyEvent: build.mutation({
                query: ({ id }) => ({
                    url: `events/${id}/copy/`,
                    method: "POST",
                }),
                invalidatesTags: [{ type: "Event", id: "LIST" }],
            }),

            deleteEvent: build.mutation({
                query: ({ id }) => ({
                    url: `events/${id}/`,
                    method: "DELETE",
                }),
                invalidatesTags: (_result, error, { id }) =>
                    error
                        ? []
                        : [
                              { type: "Event", id: "LIST" },
                              { type: "Event", id },
                          ],
            }),
        }),
    });

export const { useGetEventsQuery, useGetEventDetailQuery, useCopyEventMutation, useDeleteEventMutation } = eventsApi;

export const anonEventsApi = anonApi
    .enhanceEndpoints({
        addTagTypes: ["Event"],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getAnonEvents: build.query({
                query: ({ term, limit = 10, offset = 0 }) => ({
                    url: `search_homepage_events/`,
                    params: {
                        term,
                        limit,
                        offset,
                    },
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.results.map(({ id }) => ({
                                  type: "Event",
                                  id,
                              })),
                              { type: "Event", id: "LIST" },
                          ]
                        : [{ type: "Event", id: "LIST" }],
            }),

            getAnonEventDetail: build.query({
                query: ({ id }) => ({
                    url: `search_homepage_events/${id}/`,
                }),
                providesTags: (result, error, { id }) => (result ? [{ type: "Event", id }] : []),
            }),
        }),
    });

export const { useGetAnonEventsQuery, useGetAnonEventDetailQuery } = anonEventsApi;
